import React, { useEffect } from 'react'
import { useStoreon } from 'storeon/react'
import { routerKey } from '@storeon/router'
import * as Sentry from '@sentry/react'
import { Portal } from '@hb/web-components'
import { useAuth } from '@hooks'
import Smartlook from 'smartlook-client'
import { withModal, withHeader, empty } from '../layouts'
import getPageComponent from './pages'

const {
  H4B_DEFAULT_APP_ID: defaultAppId,
  H4B_SMARTLOOK_KEY: smartlookKey,
  H4B_GRAPHQL_URL: graphqlURL,
} = h4benv

const Page = () => {
  const {
    dispatch, user, [routerKey]: route, app,
  } = useStoreon('app', 'user', routerKey)
  const { auth } = useAuth()

  useEffect(() => {
    window.addEventListener('unhandledrejection', (event) => {
      // eslint-disable-next-line no-console
      console.error(
        `UNHANDLED PROMISE REJECTION: ${JSON.stringify(event.reason)}`,
      )
    })
    if (smartlookKey) {
      Smartlook.init(smartlookKey, {
        advancedNetwork: {
          allowedUrls: [
            graphqlURL,
          ],
        },
      })
      if (user.isAuthenticated) {
        dispatch('track/identify', user.userData)
      }
      Smartlook.record({
        emails: true,
        forms: true,
        numbers: true,
      })
    }
  }, [])

  useEffect(() => {
    Sentry.setUser({
      ...user.userData,
      now: (((new Date()).valueOf() / 1000) + (3600 * 6)),
    })
  }, [user])

  const appId = route.match.props?.appId ?? null

  useEffect(() => {
    if (appId && appId !== app.id) {
      dispatch('app/setId', appId)

      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      })
      if (params.table || params.room) {
        localStorage.setItem(`table-${appId}`, params.table || params.room)
      }
    }
  }, [appId])

  if (route.path === '/authorize') {
    const AuthComponent = getPageComponent('authorize')
    return <AuthComponent auth={auth} />
  }

  if (route.match.requiresAuth && !user.isAuthenticated) {
    auth.login()
    return <h1>Loggin in...</h1>
  }

  // TODO: add a parameter to routes to specify if they need appId
  if (!appId
    && route.path !== '/logout'
    && route.path !== '/401'
    && route.path !== '/404'
    && route.path !== `/${defaultAppId}/logout`
  ) {
    if (defaultAppId) {
      // This navigation cannot be done with the router, must be hard navigate
      window.location = `/${defaultAppId}`
    } else {
      window.location = '/404'
    }
    return null
  }

  if (app.id !== appId
    && route.path !== '/login'
    && route.path !== '/logout'
    && route.path !== '/404'
    && route.path !== '/401'
    && route.path !== `/${defaultAppId}/logout`
  ) {
    return 'Loading...'
  }

  const Component = !route.match ? getPageComponent('notFound') : getPageComponent(route.match.page)
  const isModal = typeof route.match.modal !== 'undefined'
  let Layout = null

  switch (route.match.layout) {
    case 'two':
      Layout = withHeader(Component, route.match.page, route.match.props, appId)
      break
    default:
      Layout = empty(Component, route.match.props)
  }

  if (isModal) {
    const { modal = {} } = route.match
    const Modal = getPageComponent(modal.page)

    return (
      <React.Fragment key={`page-${route.match.page}`}>
        {Layout}
        <Portal>
          {Modal
            ? withModal(
              Modal,
              modal.returnTo,
              modal.props,
              modal.returnComponent,
              modal.size,
            )
            : <h1>404</h1>}
        </Portal>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment key={`page-${route.match.page}`}>
      {Layout}
    </React.Fragment>
  )
}

export default Page
