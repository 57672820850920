import React, {
  useRef, createContext, useContext, useEffect, useState, useMemo,
} from 'react'
import { createAuthSDK } from '@h4b-dev/n1-auth-sdk'
import PropTypes from 'prop-types'

const AuthContext = createContext({
  auth: null,
  isAuthenticated: false,
  isLoading: true,
  accessToken: null,
  user: null,
  login: null,
  error: null,
})

const buildReturnUrl = () => {
  const AUTH_RETURN_ORIGIN = `${window.location.origin}/authorize`
  const AUTH_RETURN_PATHNAME = encodeURIComponent(window.location.pathname)
  const AUTH_RETURN_SEARCH = encodeURIComponent(window.location.search)
  return `${AUTH_RETURN_ORIGIN}?returnToPath=${AUTH_RETURN_PATHNAME}&returnToSearch=${AUTH_RETURN_SEARCH}`
}

const AuthProvider = ({ children, config }) => {
  const authSDKRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)

  const initializeAuth = async () => {
    setIsLoading(true)
    // if (!authSDKRef.current) {
    authSDKRef.current = createAuthSDK({
      ...config,
      redirectUri: buildReturnUrl(),
    })
    console.log('initializing auth')
    console.log('redirectUri', buildReturnUrl())
    // }
    setIsLoading(false)
  }

  const login = async () => {
    await initializeAuth()
    await authSDKRef.current.login()
  }

  useEffect(() => {
    initializeAuth()

    return () => {
      if (authSDKRef.current) {
        authSDKRef.current.cleanup()
        authSDKRef.current = null
      }
    }
  }, [config])

  const contextValue = useMemo(() => ({
    auth: authSDKRef.current,
    isLoading,
    login,
  }))

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.shape({
    domain: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    audience: PropTypes.string.isRequired,
  }).isRequired,
}

const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

export { AuthProvider, AuthContext }
export default useAuth
